// CLIENTES COMPONENT 
// MOBILE 

.clientes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0 0 2rem;
    overflow-x: hidden;

    h4{
        padding-bottom: 3rem;
        font-weight: 600;
    }

    .circles{
        width: 100vw;
        height: auto;

        .carousel{
            margin: 0 auto;
            width: 90vw;

            .carousel-inner{
                
                .carousel-item{
                     
                    .content-circle{
                        width: 80vw;
                        height: auto;
                        margin: 0 auto;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) { 
    .clientes{
        .content-circle{
            width: 60vw;
        }
    }
    
}