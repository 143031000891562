// CARDS COMPONENT 
// MOBILE 

.cards-container{
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 1rem;
    
    h4{
            font-size: 1.5rem;
            font-weight: 600;
            text-align: center;
            margin-bottom: 3rem;
    }

    .cards{
        max-width: 80vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}