// FORMULARIO COMPONENT 
// MOBILE 

.container-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4{
        // color: $violeta;
        font-weight: 600;
        margin-bottom: 2.5rem;
    }

    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .container-input{
            display: flex;
            flex-direction: column;
            justify-content: start;

            .input-form{
                border: none;
                border-radius: 3px;
                padding: .5rem;
                width: 70vw;
                max-width: 37rem;
                box-shadow: 0px 0px 3px 0px $violeta;

                &:focus{
                    border: 2px solid $naranja;
                }
            }
        }
        .captcha{
            padding-top: 1rem;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .button-form{
            color: $blanco;
            background-color: $violeta;
            border-radius: 5px;
            margin: 2rem auto;
            height: 2.5rem;
            width: 5.5rem;

            &:hover{
                color: $naranja;
            }
        }
    }
}

