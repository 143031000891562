.circle{
    height: 4.5rem;
    width: 4.5rem;
    margin: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;


    .img-circle{
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        box-shadow: 3px 3px 7px -3px rgba(96, 96, 96, 0.94);

    }
}