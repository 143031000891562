// SPLASH-CARROUSEL COMPONENT

// MOBILE 

.splash-carousel{
    background: $violeta;
    background: linear-gradient(to bottom, $violeta 65%, $blanco 95%);
    height: 40vh;
    width: 100%;
    padding-top: 2px;
    

    .contenedor{
        width: 100%;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .carousel-inner{
            height: 250px;
            width: 90%;
            margin: 0;

            .carousel-item{
                height: 230px;
                
                .img-carousel{
                    border-radius: 15px;
                    height: 230px;
                    
                }
            }
        }

        .btn-ctr{
            
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .splash-carousel{
        height: 85vh;

        .contenedor{
            height: 600px;
            
            .carousel-inner{
                height: 600px;
    
                .carousel-item{
                    height: 600px;
                    
                    .img-carousel{
                        border-radius: 15px;
                        height: 35rem;
                    }
                }
            }
    
            .btn-ctr{
    
            }
        }
    }
}