.main{
    background-image: url(../../images/fondo.png);
    background-size: contain;
    // background-attachment: fixed;
    
    hr{
        overflow: visible; /* For IE */
        padding: 0;
        border: none;
        border-top: medium double $naranja;
        color: $naranja;
        text-align: center;
        width: 40vw;
        margin: 5rem auto;

        &::after{
            content: " ";
            display: inline-block;
            position: relative;
            top: -0.7em;
            font-size: 0.8em;
            padding: 0 0.25em;
            background: $blanco;
        }
    }
}

@media (min-width: 750px) { 
    .main{
        position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}